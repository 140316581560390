@import "./styles/mixins.scss";
@import "./styles/config.scss";

.splash-screen {
  height: 100vh;
  width: 100vw;
}

html,
body {
  overflow-x: hidden;
  margin: unset;
}

.center-all-direction {
  @include align-center();
}

.MuiDialogTitle-root {
  //color: white;
}

.mp-component_azure-mp-container__idgeq {
  width: 20rem !important;
  height: 10rem !important;
}
